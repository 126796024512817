@media (max-width: 900px) {
    .header-search-container {
        padding: unset !important;
        .header-search-bar {
            padding: unset !important;
        }
    }
}

.skeleton-loader {
    min-height: 100px;
    border-radius: 10px;
}
