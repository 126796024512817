@import '../assets/scss/themes-vars.module';

.loader-linear-progress {
    background-color: rgb(210, 200, 187);
    .MuiLinearProgress-bar {
        background-color: $primaryMain;
    }
}

.loader-backdrop {
    z-index: 1111;
}

.circular-loader {
    color: rgb(210, 200, 187);
}
