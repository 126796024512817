.financial-forms{
    margin-top: 20px;
}

@media (min-width: 280px) and (max-width: 530px) {
    .financial-forms{
        margin-top: 0px;
    }
}

.menu-option {
    display: flex;
    align-items: center;
    gap: 8px;
}

.plus-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
}
