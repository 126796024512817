@import 'assets/scss/_themes-vars.module.scss';

.component-sub-box {
    background-color: white;
}

.component-container {
    height: max-content;
}

.component-sub-container {
    background-color: $primaryLight;
}

.add-property-icon-view {
    display: none;
}

@media (max-width: 920px) {
    .component-header {
        flex-direction: column-reverse;
    }
    .component-helper-button {
        justify-content: space-between;
    }
    .component-table-cell {
        padding: 8px;
    }
}

@media (max-width: 899px) {
    .documentsRightPart {
        margin-top: 20px;
        overflow: scroll;
    }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 530px) {
    .component-header {
        flex-direction: column-reverse;
    }
    
    .component-helper-button {
        padding: 0 !important;
        gap: 0 !important;
    }
    .icon-button {
        width: 100%;
        display: flex !important;
        flex-direction: row !important;
        justify-content: end;
    }
    .new-property-text {
        display: none;
    }
    .add-property-icon-view {
        display: flex;
    }
    .create-new-property-container {
        display: none;
    }
}

@media (min-width: 280px) and (max-width: 530px) {
    .component-sub-container {
        padding: 0px 10px 20px 10px;
    }
    .component-sub-box {
        padding-left: 8px;
        padding-right: 8px;
    }
    .component-header {
        padding-left: 5px;
    }
    .component-header-main-card {
        .MuiCardContent-root {
            padding: 12px;
        }
    }
    .component-data-container {
        padding-top: 0px !important;
    }
    .component-sub-header-icon-button {
        padding-left: 4px !important;
        padding-right: 4px !important;
        margin-right: 9px !important;
    }
    .new-property-container {
        .new-property-button {
            width: 10rem !important;
            margin-left: 0 !important;
        }
    }
    .card-data {
        font-size: 0.75rem;
    }
    .documentsRightPart {
        margin-top: 20px;
    }
}
