@import '../../assets/scss/_themes-vars.module.scss';

.sub-header {
    background-color: $primaryLight;
}

.sub-header-container {
    background-color: white;
}

.breadcrumb-content {
    display: flex; 
    justify-content: center;
    align-items: center;
    gap: 14px;
}

@media (min-width: 531px) and (max-width: 599px) {
    .sub-header-container {
        flex-direction: column;
        svg {
            height: 20px;
            width: 20px;
        }
    }
}

@media (min-width: 280px) and (max-width: 530px) {
    .sub-header {
        padding: 10px;
    }
    .sub-header-container {
        justify-content: center;
        .MuiGrid-root {
            width: 100%;
            justify-content: start;
            text-align: center;
        }
        svg {
            height: 16px;
            width: 16px;
        }
    }
    .breadcrumb-title {
        text-align: start;
        padding-bottom: 0;
        padding-left: 8px;
    }
    .breadcrumb-main {
        padding-top: 5px;
        padding-left: 8px;
    }
    .MuiBreadcrumbs-separator {
        margin-left: 4px;
        margin-right: 4px;
    }
}
